import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Helmet from "react-helmet";
import GlobalLayout from "components/page/GlobalLayout";
import CameraDetail from "components/cameras/detail/CameraDetailPage";

export default function R400() {
  const renderContent = data => {
    const camera = {
      model: "R400",
      type: "Dome",
      description:
        "Vandal and weather-resistant, high-resolution 4K dome with AI analytics and 3x optical zoom.",
      webHeader: data.webImage,
      mobileHeader: data.mobileImage,
      objectPosition: "20% top",
      dims: data.dims,
      pdf: "/files/specs/R400.pdf",
      specs: {
        column1: [
          {
            title: "Resolution",
            text: "8.3MP image sensor, 3840 x 2160 resolution",
          },
          {
            title: "Aperture",
            text: "F/1.5",
          },
          {
            title: "Pan / Tilt / Zoom",
            text: "2.5X optical zoom + up to 3X digital PTZ",
          },
          {
            title: "Field of View",
            text: [
              "102° - 44° Diagonal FoV",
              "94° - 39° Horizontal FoV",
              "62° - 22° Vertical FoV",
            ],
          },
          {
            title: "Focal Length",
            text: "3.6mm - 11mm motorized varifocal lens",
          },
          {
            title: "Dimensions",
            text: "134mm (5.27in) x 118mm (4.64in)",
          },
          {
            title: "Weight",
            text: "0.79kg (1.75lbs)",
          },
          {
            title: "Weather Resistance",
            text: "IP66",
          },
          {
            title: "Impact Resistance",
            text: "IK10",
          },
          {
            title: "Night Vision",
            text: "Infrared illuminators (130ft in low light)",
          },
        ],
        column2: [
          {
            title: "Onboard Storage Included",
            text: ["512GB or 30 days", "1TB or 60 days"],
          },
          {
            title: "Ethernet",
            text: "10 / 100Mbps, MDI / MDI-X Auto Sensing",
          },
          {
            title: "Power-Over-Ethernet",
            text: "Standard 802.3af for one-cable installation",
          },
          {
            title: "Power Consumption",
            text: "Max 11.7 watts",
          },
          {
            title: "Operating Temperature",
            text: ["-55˚ to 55˚ C", "-67˚ to 131˚ F"],
          },
          {
            title: "Operating Humidity",
            text: "<90% RH",
          },
          {
            title: "In the Box",
            text: "Torx key, mounting screws, anchors",
          },
          {
            title: "Baseline Steady State",
            text: "10 - 30 Kbps per camera",
          },
          {
            title: "HD WAN Live Streaming",
            text: "530 - 720 Kbps per camera",
          },
          {
            title: "CE, FCC, IC, UK, AUS, NZ, NDAA, TAA Compliant",
          },
          {
            title: "10-Year Warranty Included",
          },
        ],
      },
      accessoryCompatible: true,
      sensorCompatible: false,
      exploreModels: {
        title: "Explore Other Dome Cameras",
        subtitle:
          "Find the solution that’s best suited for your needs. Compare all camera models or view other dome cameras below.",
        cameraCards: [
          {
            image: data.r120Camera,
            mobileImage: data.r120CameraMobile,
            alt: "R120 dome camera model",
            width: "189px",
            model: "R120",
            useCase: "Ideal for maximizing coverage.",
            link: "/cameras/dome-cameras/r120",
          },
          {
            image: data.r170Camera,
            mobileImage: data.r170CameraMobile,
            alt: "R170 dome camera model",
            width: "195px",
            model: "R170",
            useCase: "Ideal for discreet visibility.",
            link: "/cameras/dome-cameras/r170",
          },
          {
            image: data.r120Camera,
            mobileImage: data.r120CameraMobile,
            alt: "R200 dome camera model",
            width: "189px",
            model: "R200",
            useCase: "Ideal for general visibility.",
            link: "/cameras/dome-cameras/r200",
          },
          {
            image: data.r230Camera,
            mobileImage: data.r230CameraMobile,
            alt: "R230 dome camera model",
            width: "200px",
            model: "R230",
            useCase: "Ideal for flexible deployments.",
            link: "/cameras/dome-cameras/r230",
          },
        ],
      },
    };
    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>Rhombus R400 4K Dome Security Camera</title>
          <meta
            name="description"
            content="4K dome video surveillance camera with modern cloud management, edge-based analytics, and durable design."
          />
          <meta
            name="thumbnail"
            content="https://rhombus.com/img/r400-seo.png"
          />
        </Helmet>
        <CameraDetail camera={camera} />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      webImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r400-header-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      mobileImage: file(
        relativePath: {
          eq: "components/cameras/detail/img/r400-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      dims: file(
        relativePath: {
          eq: "components/cameras/detail/img/r400-dims-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      r120Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r120-200-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r120CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r120-200-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r170Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r170-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, placeholder: BLURRED)
        }
      }
      r170CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r170-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 75, placeholder: BLURRED)
        }
      }
      r230Camera: file(
        relativePath: { eq: "components/cameras/img/explore-r230-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      r230CameraMobile: file(
        relativePath: {
          eq: "components/cameras/img/explore-r230-mobile-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
